import { SelectListMockService } from 'src/app/core/services/selects/selectsList';
import { PrefixMockService } from '../../../../core/services/selects/prefix.mock-service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { allRegex } from 'src/app/core/extensions/regex';
import { Country, Prefix } from 'src/app/core/models/selects/selects';
import { SelectFormMockService } from 'src/app/core/services/selects/countrySelect.mock-service';
import { ActivityListMockService } from 'src/app/core/services/selects/activityLists';
import { DatePipe } from '@angular/common';
import { FilterParams } from 'src/app/core/models/filters/filters.model';

@Component({
  selector: 'app-company-data',
  templateUrl: './company-data.component.html',
  styleUrls: ['./company-data.component.scss']
})
export class CompanyDataComponent {
  public companyData!: FormGroup;
  public cnaeControl = new FormControl('', [Validators.required]);
  public country!: Country[];
  public filteredOptions!: any[];
  public prefix!: Prefix[];
  public cnaeList: any;
  public rrppLevelList!: any[];
  public pipe!: any;
  public todayWithPipe!: any;
  /*  public minDate!: Date;
   public maxDate!: Date */

  public incomeSelectBusiness!: any[]
  public volumeSelectBusiness!: any[]
  public today = new Date();
  @ViewChild('cnaeInput') cnaeInput!: ElementRef<HTMLInputElement>;
  @Output() companyDataEvent = new EventEmitter<FormGroup>();
  @Output() filtersEvent = new EventEmitter<FilterParams>();

  constructor(
    private formBuilder: FormBuilder,
    private selectDataService: SelectFormMockService,
    private prefixMockService: PrefixMockService,
    private cnaeService: ActivityListMockService,
    private selectListService: SelectListMockService
  ) {
    this.companyData = this.formBuilder.group({
      createdFrom: [],
      createdTo: [],
      cnae: []
    });

    this.cnaeService.cnaeSelectList().subscribe(res => {
      this.cnaeList = res;
    });
    this.volumeSelectBusiness = [
      {
        value: 'A',
        viewValue: 'Menos de 100.000€'
      },
      {
        value: 'B',
        viewValue: 'Entre 100.001€ y 500.000€'
      },
      {
        value: 'C',
        viewValue: 'Entre 500.001€ y 1.500.000€'
      },
      {
        value: 'D',
        viewValue: 'Más de 1.500.000€'
      }
    ],
    this.incomeSelectBusiness = [
      {
        value: 'A',
        viewValue: 'Menos de 100.000€'
      },
      {
        value: 'B',
        viewValue: 'Entre 100.001€ y 600.000€'
      },
      {
        value: 'C',
        viewValue: 'Entre 600.001€ y 1.200.000€'
      },
      {
        value: 'D',
        viewValue: 'Más de 1.200.000€'
      }
    ]

  }


  ngOnInit() {
    this.selectDataService.selectList().subscribe(
      data => {
        this.country = data.country;
      }
    )
    this.cnaeService.cnaeSelectList().subscribe(
      data => {
        this.cnaeList = data;
      }
    )
    this.prefixMockService.preList().subscribe(
      data => {
        this.prefix = data.prefix;
      }
    )
    this.selectListService.rrppLevel().subscribe(
      data => {
        this.rrppLevelList = data
      }
    )
    this.companyData = this.formBuilder.group({
      name: ['', [Validators.required]],
      brand: ['',],
      prefix: ['',],
      phone: ['',],
      email: ['',],
      country: ['', [Validators.required]],
      expeditionCountry: ['', [Validators.required]],
      incorporationDate: ['', [Validators.required]],
      vatNumber: ['', [Validators.required, Validators.pattern(allRegex.regexCIF)]],
      salesVolume:['', [Validators.required]],
      cnae:['', [Validators.required]],
      income:['', [Validators.required]],
      levelOwnResources: ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern(allRegex.regexPin)]],
      rePassword: ['', [Validators.required]],

    });

    this.companyData.valueChanges.subscribe(res => {
      const dataFromForm = {
        cnae: this.companyData.get('cnae')?.value
      }
      const filtersToSend: FilterParams = {}
      dataFromForm.cnae ? filtersToSend.cnae = dataFromForm.cnae : null;
      const pin = this.companyData.get('password')?.value
      const repeatPin = this.companyData.get('rePassword')?.value
      if (pin !== repeatPin) {
        this.companyData.get('rePassword')?.setErrors({ rePasswordError: 'cambia el pin' })
      }
      this.companyDataEvent.emit(this.companyData);
    })

  }

  focusCnae() {
    if (this.cnaeInput.nativeElement.value) {
      this.filteredOptions = this.cnaeList;
    } else {
      this.filterCnae();
    }
  }

  filterCnae(): void {
    const filterValue = this.cnaeInput.nativeElement.value.toLowerCase();
    if (!filterValue) {
      this.companyData.get('cnae')?.reset();
    }
    this.filteredOptions = this.cnaeList.filter((o: { code: string; name: string; }) =>
      o.code.toLowerCase().includes(filterValue) ||
      o.name.toLowerCase().includes(filterValue) ||
      `${o.code.toLowerCase()} - ${o.name.toLowerCase()}`.includes(filterValue)
    );
    if (this.filteredOptions.length === 0) {
      this.companyData.get('cnae')?.reset();
    }
  }

  setCnae(event: any) {
    const cnaeData = `${event.code} ${event.name}`;
    const variablexd = this.companyData.get('cnae')?.setValue(cnaeData);
  }

  displayFn(cnae: any): string {
    return cnae && cnae.code ? `${cnae.code} - ${cnae.name}` : '';
  }

  formattedDate() {
    const birthdateValue = this.companyData.get('incorporationDate')?.value;
    this.pipe = new DatePipe('en-US');
    this.todayWithPipe = this.pipe.transform(birthdateValue, 'yyyy-MM-dd');
    this.companyData.get('incorporationDate')?.setValue(this.todayWithPipe);
  }

}

