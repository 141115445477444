<div class="document-viewer">
  <div class="header">
    <h2>Vista Previa del Documento</h2>
    <button (click)="closeModal()"><mat-icon>close</mat-icon></button>
  </div>

  <ng-container [ngSwitch]="isPdfVisible" >

    <ng-container *ngIf="isPdfVisible && safeUrl && !loader">
      <pdf-viewer
      [src]="safeUrl"
      [original-size]="false"
      [zoom-scale]="'page-width'"
      style="width: 95%; height: 300px; border-radius: 6px; overflow: hidden; margin-top: 12px"
      class="img-container">
    </pdf-viewer>
    </ng-container>

    <ng-container *ngSwitchCase="false && !loader" >
      <img [src]="safeUrl" alt="">
    </ng-container>
  </ng-container>

  <div class="skeleton-container" *ngIf="loader" >
    <ngx-skeleton-loader
      count="7"
      appearance="line"
      [theme]="{ height: '30px', width: '700px' }"
    ></ngx-skeleton-loader>
</div>

  <button class="gold-button" (click)="downloadDocument()">Descargar</button>
</div>
