import { SideNavService } from './../../../core/services/side-nav/side-nav.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LoadingService } from 'src/app/core/services/loading/loading.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  public loginForm!: FormGroup;
  public submitted: boolean = false;
  _login: any;
  public isOpen: boolean = true;
  public errorMessage: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private loadingService: LoadingService
  ) {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(3)]],
    });
  }
  ngOnInit(): void {}
  toggleEye() {
    this.isOpen = !this.isOpen;
  }

  onSubmit() {
    const username = this.loginForm.get('username')?.value;
    const pass = this.loginForm.get('password')?.value;
    this.loadingService.showLoading();
    this.authService
      .signIn(username, pass)
      .pipe(
        tap(() => {
          if (this.authService.hasRequiredRoles()) {
            this.router.navigate(['/equipo']); 
          } else {
            this.errorMessage = 'No tiene permisos para acceder a esta aplicación.';
            this.authService.signOut(); 
          }
          this.loadingService.hideLoading();
        }),
        catchError((error) => {
          this.errorMessage = 'Usuario o contraseña incorrectos. Por favor, inténtelo de nuevo'
          if (error.code === 401) {
            this.errorMessage = 'Usuario o contraseña incorrectos. Por favor, inténtelo de nuevo'
          } else {
            console.error('Error en la autenticación:', error);
          }
          this.loadingService.hideLoading();
          return of(401);
        })
      )
      .subscribe();
  }
}
