<ng-container *ngIf="!isLoading; else loadingTemplate">
  <div class="form-upload modal-content">
    <h2>Enviar documento</h2>
    <h3>Documento: {{ documentName }}</h3> 

    <div class="form-group" *ngFor="let signer of signers">
      <label>
        <mat-checkbox [(ngModel)]="signer.selected">{{ signer.fullName }}</mat-checkbox>
      </label>
    </div>

    <div class="buttons modal-actions">
      <button class="stroked-gold-button" (click)="closeModal()">Cancelar</button>
      <button class="gold-button" (click)="sendDocument()" [disabled]="isSendDisabled || isLoading">Enviar</button>
    </div>
  </div>
</ng-container>

<ng-template #loadingTemplate>
  <app-spinner></app-spinner>
</ng-template>