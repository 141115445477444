import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, of, tap } from 'rxjs';
import { stateSigningDocuments } from 'src/app/core/extensions/stateFinancing.extension';
import { getFinancingUserType } from 'src/app/core/extensions/userRole.extension';
import { FinancingService } from 'src/app/core/services/financing/financing.service';
import { DeleteConfirmationModalComponent } from 'src/app/shared/components/modals/delete-confirmation-modal/delete-confirmation-modal.component';
import { DocumentDetailModalComponent } from 'src/app/shared/components/modals/document-detail-modal/document-detail-modal.component';
import { SendDocumentModalComponent } from 'src/app/shared/components/modals/send-document-modal/send-document-modal.component';
import { UploadDocumentModalComponent } from 'src/app/shared/components/modals/upload-document-modal/upload-document-modal.component';
import { UploadSignerModalComponent } from 'src/app/shared/components/modals/upload-signer-modal/upload-signer-modal.component';
import { ViewDocumentModalComponent } from 'src/app/shared/components/modals/view-document-modal/view-document-modal.component';

@Component({
  selector: 'app-financing-contract',
  templateUrl: './financing-contract.component.html',
  styleUrls: ['./financing-contract.component.scss']
})
export class FinancingContractComponent implements OnInit {
  @Input() userData!: any;
  public loader: boolean = false;
  signers: any[] = [];
  documents: any[] = [];
  signersTableData: any[] = []; 
  tableData: any[] = []; 
  offset: number = 10; 
  public alert: boolean = false;
  public allertMessage!: string;
  public alertStatus!: string;
  isFirstLoad: boolean = true;
  displayedColumnsSigners = ['fullName', 'email', 'phone', 'identifier', 'edit', 'delete'];
  displayedColumnsDocuments = ['view', 'documentos', 'state', 'date', 'action1', 'action2'];
  isCompany!: boolean;

  stateSigningDocuments = stateSigningDocuments

  constructor(private dialog: MatDialog, private cdr: ChangeDetectorRef,  private financingService: FinancingService,     private snackBar: MatSnackBar, ) {
}

  ngOnInit() {
    this.loadSigners(); 
    this.loadSignerDocuments(); 
    this.documents = this.userData?.operationInfo?.documents || [];
    this.updateTableData();
    this.isCompany = getFinancingUserType(this.userData.customer.identifier);
  }

  loadSigners() {
    this.loader = true;
    this.financingService.listFinancingSigners(this.userData.id).pipe(
      tap((signers) => {
        this.loader = false;
        this.signers = signers.map((signer) => ({
          ...signer,
          fullName: `${signer.name} ${signer.surname}`.trim()
        }));
        this.updateSignersTableData();

        if (!this.isFirstLoad && this.signers.length === 0) {
          this.showAlert('No hay firmantes disponibles.', 'info');
        }
      }),
      catchError((error) => {
        this.loader = false;
        console.error('Error al cargar los firmantes:', error);

        if (!this.isFirstLoad) {
          this.showAlert('Error al cargar los firmantes.', 'ko');
        }
        return of(null);
      })
    ).subscribe(() => {
    });
  }

  loadSignerDocuments() {
    this.loader = true;
    this.financingService.getSignedDocuments(this.userData.id).subscribe(
      (documents) => {
        this.loader = false;
        this.documents = documents.map((document) => ({
          ...document,
          date: new Date(document.date)
        }));
        this.updateTableData();

        if (!this.isFirstLoad && this.documents.length === 0) {
          this.showAlert('No hay documentos firmados disponibles.', 'info');
        }
      },
      (error) => {
        this.loader = false;
        
        if (!this.isFirstLoad) {
          this.showAlert('Error al cargar los documentos firmados.', 'ko');
        }
      }
    );
  }
  openModal(component: any, config: any = {}) {
    return this.dialog.open(component, config).afterClosed();
  }

  openUploadSignerDocumentModal() {
    const dialogRef = this.dialog.open(UploadDocumentModalComponent, {
      data: { idFinancing: this.userData.id, type: 'signers', existingDocuments: this.documents }
    });

    dialogRef.afterClosed().subscribe((newDocument) => {
      if (newDocument) {
        this.showAlert('Documento subido con éxito.', 'ok');
        this.loadSignerDocuments(); 
      } else {
        console.warn('No se subió ningún documento.');
      }
    });
  }

  openUploadSignerModal() {
    const dialogRef = this.dialog.open(UploadSignerModalComponent, { data: { idFinancing: this.userData.id } });

    dialogRef.afterClosed().subscribe((newSigner) => {
      if (newSigner) {
        this.showAlert('Firmante agregado con éxito.', 'ok');
        this.loadSigners();
      } else {
        this.showAlert('No se agregó ningún firmante.', 'info');
      }
    });
  }
  openEditSignerModal(signer: any) {
    const [name, ...surnameParts] = signer.fullName.split(' ');
    const surname = surnameParts.join(' ');
    const dialogRef = this.dialog.open(UploadSignerModalComponent, {
      data: {
        idFinancing: this.userData.id,
        idSigner: signer.id,
        isEdit: true,
        name,
        surname,
        email: signer.email,
        phone: signer.phone,
        identifier: signer.identifier
      }
    });
    dialogRef.afterClosed().subscribe(updatedSigner => {
      if (updatedSigner) {
        this.showAlert('Firmante actualizado con éxito.', 'ok');   
        this.loadSigners();
      } else {
        this.showAlert('No se actualizó el firmante.', 'info');
      }
    });
  }

  openSendDocumentModal(document: any) {
    if (this.signers.length === 0) {
      this.showAlert('No puedes enviar un documento sin añadir firmantes.', 'ko');
      return; 
    }
  
    this.openModal(SendDocumentModalComponent, {
      data: { 
        signers: this.signers, 
        financingId: this.userData.id, 
        documentId: document.id, 
        documentName: document.name
      }
    }).subscribe((result) => {
      if (result?.error) {
        this.showAlert(result.error, 'ko');
      } else if (result) {
        this.showAlert('Documento enviado con éxito.', 'ok');
        this.loadSignerDocuments(); 
      } else {
        this.showAlert('El documento no fue enviado.', 'info');
      }
    });
  }

  openDocumentDetailModal(document: any) {
    if (document.state !== 'SEND' && document.state !== 'SIGNED') {
      this.showAlert('El documento debe estar en estado "Enviado" o "Firmado" para ver los detalles.', 'ko');
      return;
    }

    this.openModal(DocumentDetailModalComponent, { 
      data: { 
        date: document.date, 
        signers: document.signers 
      } 
    });
  }


  cancelDocument(document: any) {
    if (!document?.id) {
      console.error('Invalid document ID:', document);
      return;
    }

    this.financingService.changeDocumentState(this.userData.id, document.id, 'CANCELLED')
      .subscribe({
        next: () => {
          const index = this.documents.findIndex(doc => doc.id === document.id);
          if (index > -1) {
            this.documents[index].documentState = 'Cancelado';
            this.updateTableData();
          }
          this.showAlert('Documento cancelado con éxito.', 'ok');
        },
        error: (error) => {
          console.error('Error updating document state:', error);
          this.showAlert('Error al cancelar el documento.', 'ko');
        }
      });
  }

  confirmDeleteSigningDocument(document: any) {
    const dialogRef = this.dialog.open(DeleteConfirmationModalComponent, {
      data: { message: `¿Estás seguro de que deseas eliminar el documento "${document.name}"?` }
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.loader = true;
        this.financingService.deleteSigningDocument(this.userData.id, document.id).subscribe(
          () => {
            this.loader = false;
            this.documents = this.documents.filter(doc => doc.id !== document.id);
            this.updateTableData(); 
            this.showAlert('Documento eliminado con éxito.', 'ok');
          },
          (error) => {
            this.loader = false;
            console.error('Error al eliminar el documento:', error);
            this.showAlert('Error al eliminar el documento.', 'ko');
          }
        );
      }
    }); 
  }

  confirmDeleteSigner(signer: any) {
    const signerHasDocuments = this.documents.some(doc => 
      doc.signers?.some((signerDoc: any) => signerDoc.signerId === signer.id) 
    );
    
    if (signerHasDocuments) {
      this.showAlert('No puedes eliminar un firmante asociado a documentos enviados.', 'ko');
      return;
    }
  
    const dialogRef = this.dialog.open(DeleteConfirmationModalComponent, {
      data: { message: `¿Estás seguro de que deseas eliminar al firmante "${signer.fullName}"?` }
    });
  
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.loader = true;
  
        this.financingService.deleteSignerDocument(this.userData.id, signer.id).subscribe({
          next: () => {
            this.loader = false;
            this.signers = []; 
            this.signersTableData = []; 
            this.cdr.detectChanges();
  
            this.showAlert('Firmante eliminado con éxito.', 'ok');

            this.loadSigners();
          },
          error: (error) => {
            this.loader = false;
            console.error('Error al eliminar el firmante:', error);
            this.showAlert('Error al eliminar el firmante.', 'ko');
          }
        });
      }
    });
  }

  updateTableData() {
    this.tableData = this.documents.map((document) => ({
      id: document.id,
      previewUrl: document.previewUrl,
      name: document.name || 'Documento',
      state: document.documentState || 'Pendiente',
      date: document.date || new Date(),
      signers: document.signers || [],
      actions: 'Enviar'
    }));
  }

  updateSignersTableData() {
    this.signersTableData = [...this.signers]; 
  }
  
  openViewDocumentModal(document: any) {
    const allowedExtensions = ['pdf', 'jpg', 'png'];
    const documentExtension = document.name.split('.').pop().toLowerCase();

    if (allowedExtensions.includes(documentExtension)) {
      this.dialog.open(ViewDocumentModalComponent, {
        data: {
          idFinancing: this.userData.id,
          idDocument: document.id,
          format: documentExtension,
          name: document.name,
          url: document.previewUrl,
          isSignedDocument: true
        }
      });
    } else {
      this.showAlert('Formato no soportado para visualización.', 'ko');
    }
  }



  showAlert(message: string, status: string) {
    this.allertMessage = message;
    this.alertStatus = status;
    this.alert = true;

  }
}
