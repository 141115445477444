<form [formGroup]="uploadForm" class="form-upload" (ngSubmit)="onSubmit()">
  <div class="button-container" *ngIf="!hasImage">
    <input type="file" (change)="onFileSelected($event)" accept="application/pdf" id="fileInput">
    <label for="fileInput" class="button-upload">
      <span>{{ upload ? 'Cambiar Documento' : 'Seleccionar Documento' }}</span>
      <span>PDF</span>
    </label>
  </div>
  <div *ngIf="errorMessage" class="error-message">
    <mat-icon color="warn">error</mat-icon>
    <span>{{ errorMessage }}</span>
  </div>
  <div class="img-preview" *ngIf="preview">
    <mat-icon *ngIf="!previewIsImage">picture_as_pdf</mat-icon>
    <p>{{ truncateFileName(nameFile!) }}</p>
  </div>
  <div class="form-group">
    <label for="comment">Descripción</label>
    <input formControlName="comment" id="comment">
  </div>
  <div class="buttons">
  <button class="stroked-gold-button" (click)="onCancel()" [disabled]="uploading">Cancelar</button>
  <button type="submit" class="gold-button" [disabled]="!selectedFile || uploading">
    <span *ngIf="!isLoading">Subir Documento</span>
    <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
  </button>
</div>
</form>
