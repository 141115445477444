<div class="form">
  <h2>Nuevo / Editar firmante</h2>
  <form [formGroup]="signerForm" (ngSubmit)="onSubmit()">
    <div class="form-content">
      <div class="content">
        <div class="inputs">
          <label>Nombre</label>
          <input formControlName="name" required maxlength="50" />
          <div *ngIf="signerForm.get('name')?.invalid && signerForm.get('name')?.touched" class="error">
            <span *ngIf="signerForm.get('name')?.hasError('required')">El nombre es obligatorio.</span>
            <span *ngIf="signerForm.get('name')?.hasError('minlength')">Debe tener al menos 3 caracteres.</span>
          </div>
        </div>
        <div class="inputs">
          <label>Apellidos</label>
          <input formControlName="surname" required maxlength="50" />
          <div *ngIf="signerForm.get('surname')?.invalid && signerForm.get('surname')?.touched" class="error">
            <span *ngIf="signerForm.get('surname')?.hasError('required')">El apellido es obligatorio.</span>
            <span *ngIf="signerForm.get('surname')?.hasError('minlength')">Debe tener al menos 3 caracteres.</span>
          </div>
        </div>
        <div class="inputs">
          <label>DNI</label>
          <input formControlName="identifier" required />
          <div *ngIf="signerForm.get('identifier')?.invalid && signerForm.get('identifier')?.touched" class="error">
            <span *ngIf="signerForm.get('identifier')?.hasError('required')">El DNI es obligatorio.</span>
            <span *ngIf="signerForm.get('identifier')?.hasError('pattern')">
              El DNI debe tener 8 dígitos seguidos de una letra (e.g., 12345678A).
            </span>
          </div>
        </div>
        <div class="inputs">
          <label>Email</label>
          <input formControlName="email" required />
          <div *ngIf="signerForm.get('email')?.invalid && signerForm.get('email')?.touched" class="error">
            <span *ngIf="signerForm.get('email')?.hasError('required')">El email es obligatorio.</span>
            <span *ngIf="signerForm.get('email')?.hasError('email')">El email no es válido.</span>
          </div>
        </div>
        <div class="inputs">
          <label>Teléfono</label>
          <input formControlName="phone" required maxlength="9" />
          <div *ngIf="signerForm.get('phone')?.invalid && signerForm.get('phone')?.touched" class="error">
            <span *ngIf="signerForm.get('phone')?.hasError('required')">El teléfono es obligatorio.</span>
            <span *ngIf="signerForm.get('phone')?.hasError('pattern')">Debe tener exactamente 9 dígitos.</span>
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <button type="button" class="stroked-gold-button" (click)="onCancel()">Cancelar</button>
      <button type="submit" [disabled]="!signerForm.valid" class="gold-button">Agregar</button>
    </div>
  </form>
</div>
