import { Component, Injector, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { IconButton } from 'src/app/core/models/headers/link.model';
import { HeaderLinksService } from 'src/app/core/services/headerLinks/link.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { CardsCreateComponent } from './cards-create/cards-create.component';
@Component({
  selector: 'app-user-cards',
  templateUrl: './user-cards.component.html',
  styleUrls: ['./user-cards.component.scss']
})
export class UserCardsComponent {
  _userData:any;
  public cardData: any[] | null = null;
  public userId: any;
  public subLinks!: any[]
  public selectedLink!: IconButton;
  public requestedComponent: any = null;
  public hiddenNoCardsMsg!: boolean;
  public isLoading: boolean = true;  

  @Input() set userData(val:any){
    if(!val){
      return
    }else {
     this._userData = val
    }
  }

  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef })

  contenidoSeleccionado: string | null = null;
  dynamicComponentContainer!: ViewContainerRef;
  dynamicInjector!: Injector;


  constructor(private cardService: UserService, private linksService: HeaderLinksService,  private injector: Injector, private cardServiceInfo: UserService) {
    this.subLinks = this.linksService.getCardsIconButtons()
    this.selectedLink = this.subLinks[0]
   }

  ngOnInit(): void {
    this.getCardData()
    this.getCardInfo()
    if (this.cardData && this.cardData.length === 0) {
      this.requestedComponent = CardsCreateComponent;
    }
  }

   getCardData() {
    this.userId = this._userData.id
    this.cardService.getAllCards(this.userId).subscribe(res => {
      this.cardData = res
      this.isLoading = false;
      if(this.cardData){
        this.dynamicInjector = Injector.create({
          providers: [{ provide: 'CARD_DATA', useValue: this.cardData }],
          parent: this.injector
        });
      }
    })
  }

  getCardInfo() {
    this.cardServiceInfo.getUserInfo().subscribe(res => {
    })
  }

  receiveSelectedLink(link: IconButton){
    this.selectedLink = link

  }

  toCreateCard(): void {
    this.requestedComponent = CardsCreateComponent; 
    this.hiddenNoCardsMsg = true
  }
}
