import { DatePipe, registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Data, InfoTable } from 'src/app/core/models/info-table/info-table';
import { formatDateOnlyDay } from '../../../core/extensions/functions';
import { provideNativeDateAdapter } from '@angular/material/core';
registerLocaleData(localeEs, 'es');

@Component({
  selector: 'app-info-table',
  templateUrl: './info-table.component.html',
  styleUrls: ['./info-table.component.scss'],
  providers: [provideNativeDateAdapter()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoTableComponent {
  public loader: boolean = true;
  @Input() infoTable!: InfoTable;
  @Output() editEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() loaderHidden: any;

  public eighteenYearsAgo!: Date;

  formatDateOnlyDay = formatDateOnlyDay;

  public editedField: FormControl = new FormControl();

  constructor(private datePipe: DatePipe, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.loader = this.loaderHidden;
  }

  showEdit(item: Data) {
    this.infoTable.data.forEach((elem) => {
      if (elem === item) {
        elem.editing = true; 
        const initialValue = elem.value;
        this.editedField.setValue(initialValue);
      } else { 
        elem.editing = false; 
      }
    });
  }

  hideEdit(item: Data) {
    item.editing = false;
  }

  editField(item: Data) {
    const rawValue = this.editedField.value;
  
    if (item.isDateFuture || item.isDatePast) {
      item.value = this.datePipe.transform(rawValue, 'yyyy-MM-dd') || ''; 
    } else {
      item.value = rawValue || '';
    }
  
    console.log('Emitido desde InfoTableComponent:', {
      key: item.key,
      value: item.value,
      sectionKey: this.infoTable.sectionKey,
    });
  
    this.editEvent.emit({
      key: item.key,
      value: item.value,
      sectionKey: this.infoTable.sectionKey,
    });
  
    item.editing = false;
  
    this.cdr.detectChanges();
  }
  

  filterFuture = (d: Date | null): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return d !== null && d >= today;
  };
  

  filterPast = (d: Date | null): boolean => {
    if (d === null) {
      return false;
    }

    const today = new Date();
    this.eighteenYearsAgo = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );

    return d <= this.eighteenYearsAgo;
  };

  isDate(value: any): boolean {
    if (typeof value === 'string') {
      const dateRegex = /^(?:(\d{4})[-/](\d{2})[-/](\d{2})|(\d{2})[-/](\d{2})[-/](\d{4}))(:(\d{2}):(\d{2}):?(\d{2})?)?$/;

      return dateRegex.test(value);
    }
    return false;
  }
}
