<div class="form-upload">
  <h2>Detalle del Documento</h2>
  <p>Fecha de envío: {{ data.date | date: 'dd/MM/yyyy HH:mm' }}</p>

  <div *ngFor="let signer of data.signers" class="signer-box form-group">
    <p><strong>Nombre Completo:</strong> {{ signer.name + ' ' + signer.surname }}</p>
    <p><strong>Email:</strong> {{ signer.email }}</p>
    <p><strong>Teléfono:</strong> {{ signer.phone }}</p>
    <p><strong>CIF/NIF:</strong> {{ signer.identifier }}</p>
    <p class="signed-text">{{ stateSigningDocuments(signer.signerState) }}</p>
  </div>

  <div class="actions buttons">
    <button class="stroked-gold-button" (click)="closeModal()">Cerrar</button>
  </div>
</div>
  