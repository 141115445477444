import { Component, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { statusCard, typeCard } from 'src/app/core/extensions/cards.extension';

@Component({
  selector: 'app-cards-table',
  templateUrl: './cards-table.component.html',
  styleUrls: ['./cards-table.component.scss'],
})
export class CardsTableComponent {
  public loaded!: boolean;
  public state: any;
  public cardId!: string;
  public accountId!: string;
  public displayedColumns: string[] = [
    'cardholder',
    'card',
    'type',
    'account',
    'detail',
  ];

  public dataSource = new MatTableDataSource<any>();
  public count = Math.floor(300 / 40);
  public showDetail: boolean = false;
  public role!: string;

  statusCard = statusCard;
  typeCard = typeCard;

  constructor(@Inject('CARD_DATA') public cardData: any) {}

  ngOnInit() {
    this.role = localStorage.getItem('type')!;
    this.getCards();
  }

  getCards() {
    this.cardData.map((res: any) => {

        this.dataSource = new MatTableDataSource(this.cardData);
    })
    this.loaded = true;
  }


  openDetail(idCard: string, accountId: string) {
    this.showDetail = true;
    this.accountId = accountId;
    this.cardId = idCard;
  }


  detailEmit(status: boolean) {
    this.showDetail = status;
  }
}
