import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { FinancingService } from 'src/app/core/services/financing/financing.service';

@Component({
  selector: 'app-view-document-modal',
  templateUrl: './view-document-modal.component.html',
  styleUrls: ['./view-document-modal.component.scss']
})
export class ViewDocumentModalComponent implements OnInit {
  safeUrl: SafeResourceUrl | null = null;
  private blob: Blob | null = null;
  public isPdfVisible: boolean = false;
  public loader: boolean = true
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { idFinancing: string, idDocument: string, format: string, name: string, url: string, isSignedDocument: boolean },
    public dialogRef: MatDialogRef<ViewDocumentModalComponent>,
    private financingService: FinancingService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
  
    if (this.data.isSignedDocument) {
      this.fetchSignedDocument();
    } else {
      this.fetchDocument();
    }
  }

  fetchDocument(): void {
    this.financingService.downloadDocument(this.data.idFinancing, this.data.idDocument).subscribe({
      next: (file: Blob) => {
        this.handleFile(file);
      },
      error: (err) => {
        this.handleError(err);
      }
    });
  }
  
  fetchSignedDocument(): void {
    this.financingService.downloadSignedDocument(this.data.idFinancing, this.data.idDocument).subscribe({
      next: (file: Blob) => {
        this.handleFile(file);
      },
      error: (err) => {
        this.handleError(err);
      }
    });
  }
  handleFile(file: Blob): void {
  
    if (file.type === 'application/pdf') {
      this.blob = file;
      this.safeUrl = URL.createObjectURL(file);
      this.isPdfVisible = true;
      this.loader = false;
    } else {
      this.blob = file;
      this.safeUrl = this.sanitizeUrl(URL.createObjectURL(file));
      this.isPdfVisible = false;
      this.loader = false;
    }
    this.cdr.detectChanges(); 
  }
  handleError(err: any): void {
    console.error('Error al descargar el documento:', err);
    alert('No se pudo cargar el documento.');
  }

  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  downloadDocument(): void {
    if (this.blob) {
      const url = window.URL.createObjectURL(this.blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.data.name || 'documento';
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      alert('El documento no está disponible para descargar.');
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
