import { DatePipe, formatDate, registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  formatAsPercentage,
  formatDateOnlyDay,
  setCurrencyFormat,
} from 'src/app/core/extensions/functions';
import { stateFinancing } from 'src/app/core/extensions/stateFinancing.extension';
import { FINANCING_STATES } from 'src/app/core/extensions/states.extension';
import { getFinancingUserType } from 'src/app/core/extensions/userRole.extension';
import { Contract, Products } from 'src/app/core/models/financing/financing';
import { FinancingService } from 'src/app/core/services/financing/financing.service';
import { InfoTableComponent } from 'src/app/shared/components/info-table/info-table.component';
import { ModalStatesComponent } from 'src/app/shared/components/modals/modal-states/modal-states.component';
registerLocaleData(localeEs, 'es');

@Component({
  selector: 'app-financing-detail',
  templateUrl: './financing-detail.component.html',
  styleUrl: './financing-detail.component.scss',
})
export class FinancingDetailComponent {
  @ViewChild(InfoTableComponent) infoTableComponent!: InfoTableComponent;
  public personalInfo!: any;
  public operationInfo!: any;
  public infoComercial: any = [];
  public salesPoint: any = [];
  public infoClientFreelance: any = [];
  public infoClientCompany: any = [];
  public infoClient!: any;
  public infoAddress: any = [];
  public infoShippingAddress: any = [];
  public products: Products[] = [];
  public conditions: any = [];
  public resume: any = [];
  public isCompany!: boolean;
  public loader: boolean = true;
  public offset: number = 4;
  public file!: Blob;
  public identifier!: string;
  public selectedState!: string;
  public previousState!: string;
  public stateSelectList!: any;
  public showAlert: boolean = false;
  public alertMessage: string = '';
  public alertStatus: string = 'ko';

  formatAsPercentage = formatAsPercentage;
  stateFinancing = stateFinancing;
  formatDate = formatDate;
  formatDateOnlyDay = formatDateOnlyDay;

  @Input() userData!: any;

  public displayedColumns: string[] = [
    'contract',
    'status',
    'date',
  ];

  hideAlert() {
    this.showAlert = false;
  }

  public financingStates = [
    {
      value: FINANCING_STATES.PENDING,
      availableStates: [
        FINANCING_STATES.PROCESSING,
        FINANCING_STATES.CANCELLED,
      ],
    },
    {
      value: FINANCING_STATES.PROCESSING,
      availableStates: [
        FINANCING_STATES.APPROVED,
        FINANCING_STATES.CANCELLED,
        FINANCING_STATES.DENIED,
      ],
    },
    {
      value: FINANCING_STATES.APPROVED,
      availableStates: [FINANCING_STATES.CANCELLED, FINANCING_STATES.COMPLETED],
    },
    {
      value: FINANCING_STATES.CANCELLED,
      availableStates: [FINANCING_STATES.PROCESSING],
    },
    {
      value: FINANCING_STATES.DENIED,
      availableStates: [FINANCING_STATES.PROCESSING],
    },
    {
      value: FINANCING_STATES.COMPLETED,
      availableStates: [FINANCING_STATES.CANCELLED],
    },
  ];
  private stateActions: Record<string, () => void> = {
    [FINANCING_STATES.PROCESSING]: () =>
      this.changeOperationState('PROCESSING'),
    [FINANCING_STATES.PENDING]: () => this.changeOperationState('PENDING'),
    [FINANCING_STATES.CANCELLED]: () => this.changeOperationState('CANCELLED'),
    [FINANCING_STATES.APPROVED]: () => this.changeOperationState('APPROVED'),
    [FINANCING_STATES.DENIED]: () => this.changeOperationState('DENIED'),
    [FINANCING_STATES.COMPLETED]: () => this.changeOperationState('COMPLETED'),
  };

  constructor(
    private financingService: FinancingService,
    private router: Router,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef
  ) {}

  canEdit: boolean = false;

  ngOnInit() {
    this.initializeComponent();
  }

  private initializeComponent() {
    if (!this.userData) return;
  
    this.operationInfo = this.userData;
    this.personalInfo = this.userData.customer;
    this.products = this.operationInfo.products;
    this.loader = false;
    this.selectedState = this.operationInfo.state;
    this.previousState = this.selectedState;
  
    this.getStateSelectList();
    this.getFinancing();
  
    this.conditions = {
      title: 'Condiciones de financiación',
      icon: 'checklist',
      data: [
        { key: 'type', name: 'Tipo', value: this.operationInfo.type || '-', editable: false },
        { key: 'downPayment', name: 'Entrada Inicial', value: setCurrencyFormat(this.operationInfo.downPayment) || '-', editable: false },
        { key: 'firstPayment', name: 'Fecha de Primer Pago', value: formatDateOnlyDay(this.userData.installments[0].date) || '-', editable: this.operationInfo.state === FINANCING_STATES.APPROVED, isDateFuture: true },
        { key: 'monthsTerm', name: 'Plazo', value: `${this.operationInfo.monthsTerm} meses` || '-', editable: false },
        { key: 'gracePeriod', name: 'Carencia', value: `${this.operationInfo.gracePeriod} meses` || '-', editable: false },
        { key: 'insurance', name: 'Prima Seguro Mensual', value: setCurrencyFormat(this.operationInfo.insurance) || '-', editable: false },
        { key: 'financedCapital', name: 'Importe financiado', value: setCurrencyFormat(this.operationInfo.financedCapital) || '-', editable: false },
        { key: 'tin', name: 'TIN', value: formatAsPercentage(this.operationInfo.tin) || '-', editable: false },
        { key: 'tae', name: 'TAE', value: formatAsPercentage(this.operationInfo.tae) || '-', editable: false },
      ],
    };
  
    this.resume = {
      title: 'Resultado operación',
      icon: 'calculate',
      data: [
        {
          key: 'gracePeriod',
          name: this.operationInfo.gracePeriod === 0 ? 'Cuota Carencia' : `Cuota Carencia (x${this.operationInfo.gracePeriod} meses)`,
          value: this.operationInfo.gracePeriod !== 0 ? setCurrencyFormat(this.operationInfo.installments[0]?.payment) : '-',
        },
        {
          key: 'installments',
          name: 'Cuota Regular',
          value: setCurrencyFormat(this.operationInfo.installments[this.operationInfo.installments.length - 2]?.payment || '-') || '-',
          editable: false,
        },
        {
          key: 'openingCommission',
          name: `Comisión apertura (${formatAsPercentage(this.operationInfo.openingCommission)})`,
          value: setCurrencyFormat((this.operationInfo.totalAmount * this.operationInfo.openingCommission).toString()) || '-',
          editable: false,
        },
        {
          key: 'residualValue',
          name: 'Última cuota',
          value: setCurrencyFormat(this.operationInfo.installments[this.operationInfo.installments.length - 1]?.payment) || '-',
          editable: false,
        },
      ],
    };
  
    this.cdr.detectChanges();
  }

getFinancing() {
    if (!this.personalInfo) return;
    this.isCompany = getFinancingUserType(this.personalInfo.identifier);
    this.loader = false;
    const formatAddress = (addr: any) => `${addr.street || '-'} ${addr.city || '-'} ${addr.zip || '-'} ${addr.province || '-'}`;

    this.infoComercial = {
        title: 'Datos comercial',
        icon: 'store',
        data: [
            { key: 'name', name: 'Nombre comercial', value: `${this.operationInfo?.commercial?.name || '-'} ${this.operationInfo?.commercial?.surname || '-'}`, editable: false },
            { key: 'email', name: 'Email', value: this.operationInfo?.commercial?.email || '-', editable: false },
            { key: 'phone', name: 'Teléfono', value: this.operationInfo?.commercial?.phone, editable: false },
        ].filter(item => item.key !== 'phone' || item.value),
    };

    this.salesPoint = {
        title: 'Datos Punto Venta',
        icon: 'store',
        data: [
            { key: 'name', name: 'Razón social', value: this.operationInfo?.salesPoint?.name || '-', editable: false },
            { key: 'email', name: 'Email', value: this.operationInfo?.salesPoint?.email || '-', editable: false },
            { key: 'phone', name: 'Teléfono', value: this.operationInfo?.salesPoint?.phone, editable: false },
            { key: 'cif', name: 'CIF', value: this.operationInfo?.salesPoint?.identifier || '-', editable: false },
            { key: 'address', name: 'Dirección', value: formatAddress(this.operationInfo?.salesPoint?.address || {}), editable: false },
        ].filter(item => item.value !== '-'),
    };

    this.infoClient = {
        title: 'Datos Cliente',
        icon: 'face',
        sectionKey: 'client',
        data: [
            { key: 'type', name: 'Tipo', value: this.isCompany ? 'Empresa' : 'Autónomo', editable: false },
            { key: 'name', name: this.isCompany ? 'Razón social' : 'Nombre', value: this.personalInfo?.name || '-', editable: this.isEditable(this.operationInfo.state) },
            this.isCompany ? {key: 'contactName',name: 'Nombre Contacto',value: this.personalInfo?.nameContact ? `${this.personalInfo.nameContact} ${this.personalInfo.surnameContact || ''}`.trim() : '-',editable: this.isEditable(this.operationInfo.state),} : null,
            { key: 'email', name: 'Email', value: this.personalInfo?.email || '-', editable: this.isEditable(this.operationInfo.state) },
            { key: 'phone', name: 'Teléfono', value: this.personalInfo?.phone || '-', editable: this.isEditable(this.operationInfo.state) },
            { key: 'identifier', name: this.isCompany ? 'CIF' : 'DNI', value: this.personalInfo?.identifier || '-', editable: this.isEditable(this.operationInfo.state) },
            { key: 'iban', name: 'IBAN', value: this.personalInfo?.iban || '-', editable: this.isEditable(this.operationInfo.state) },
        ].filter(field => field !== null),
    };

    this.infoAddress = {
        title: 'Dirección Facturación',
        icon: 'home',
        data: [
            { key: 'address', name: 'Dirección', value: this.personalInfo.address.street || '-', editable: this.isEditable(this.operationInfo.state) },
            { key: 'city', name: 'Población', value: this.personalInfo.address.city || '-', editable: this.isEditable(this.operationInfo.state) },
            { key: 'province', name: 'Provincia', value:  this.personalInfo.address.province || '-', editable: this.isEditable(this.operationInfo.state) },
            { key: 'zip', name: 'CP', value:  this.personalInfo.address.zip || '-', editable: this.isEditable(this.operationInfo.state) },
        ],
        sectionKey: 'address',
    };

    this.infoShippingAddress = {
        title: 'Dirección Envío',
        icon: 'local_shipping',
        data: [
            { key: 'address', name: 'Dirección', value:  this.personalInfo.shippingAddress.street || '-', editable: this.isEditable(this.operationInfo.state) },
            { key: 'city', name: 'Población', value:  this.personalInfo.shippingAddress.city || '-', editable: this.isEditable(this.operationInfo.state) },
            { key: 'province', name: 'Provincia', value:  this.personalInfo.shippingAddress.province || '-', editable: this.isEditable(this.operationInfo.state) },
            { key: 'zip', name: 'CP', value:  this.personalInfo.shippingAddress.zip || '-', editable: this.isEditable(this.operationInfo.state) },
        ],
        sectionKey: 'shippingAddress',
    };

    this.cdr.detectChanges();
}

  getProductInfo(product: any) {
    return {
      icon: '',
      title: '',
      data: [
        {
          key: 'description',
          name: `x${product.quantity} ${product.name}`,
          value: setCurrencyFormat(product.unitPrice) || '-',
          editable: false,
        },
        {
          key: 'brand',
          name: 'Marca',
          value: product.brand || '-',
          editable: false,
        },
        {
          key: 'model',
          name: 'Modelo',
          value: product.model || '-',
          editable: false,
        },
      ],
      sectionKey: 'product'
    };
  }

  showAlertMessage(message: string, status: string) {
    this.alertMessage = message;
    this.alertStatus = status;
    this.showAlert = false;
    setTimeout(() => (this.showAlert = true), 0);
  }

  getStateSelectList() {
    this.stateSelectList = this.financingStates.find(({ value }) => value === this.selectedState);
  }

  private changeOperationState(state: string) {
    if (state === FINANCING_STATES.COMPLETED) {
      if (!this.validateFirstPayment()) {
        this.selectedState = this.previousState;
        this.operationInfo.state = this.previousState;
        return;
      }
    }
  
    if (
      state === FINANCING_STATES.APPROVED ||
      state === FINANCING_STATES.COMPLETED
    ) {
      if (!this.validateApprovedAndCompletedConditions()) {
        this.showAlertMessage(
          'No se puede cambiar al estado seleccionado. Verifique que los datos de facturación estén completos y válidos.',
          'ko'
        );
        this.selectedState = this.previousState;
        this.operationInfo.state = this.previousState;
        return;
      }
    }

    this.financingService.changeStateOperation(this.operationInfo.id, state).subscribe({
      next: () => this.onStateChangeSuccess(state),
      error: (err) => this.onStateChangeError(err, state),
    });
  }
  
  private validateApprovedAndCompletedConditions(): boolean {
    const { email, phone, iban } = this.personalInfo;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      this.showAlertMessage('No se puede cambiar al estado seleccionado. El email no es válido.', 'ko');
      return false;
    }
    const phoneRegex = /^\d{9}$/;
    if (!phone || !phoneRegex.test(phone)) {
      this.showAlertMessage('No se puede cambiar al estado seleccionado. El teléfono debe ser un número de 9 dígitos.', 'ko');
      return false;
    }
    const ibanRegex = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/;
    if (!iban || !ibanRegex.test(iban)) {
      this.showAlertMessage('No se puede cambiar al estado seleccionado. El IBAN no es válido.', 'ko');
      return false;
    }
  
    return true;
  }
  
private revertState() {
  this.selectedState = this.previousState;
  this.operationInfo.state = this.previousState;
}

private onStateChangeSuccess(state: string) {
  this.operationInfo.state = state;
  this.selectedState = state;
  this.previousState = state;
  this.initializeComponent();
  this.showAlertMessage(`Operación cambiada a ${stateFinancing(state)}`, 'ok');
}

private onStateChangeError(err: any, state: string) {
  console.error('Error:', err);
  this.revertState();
  this.getFinancing();
  this.showAlertMessage(`Error al cambiar el estado a ${stateFinancing(state)}`, 'ko');
}

private validateFirstPayment(): boolean {
  const firstPayment = this.userData?.installments[0]?.date;

  if (!firstPayment || firstPayment === '-') {
    this.showAlertMessage(
      'No se puede cambiar al estado seleccionado. Verifique que se ha configurado una fecha de primer pago futura e inténtelo de nuevo.',
      'ko'
    );
    return false;
  }
  return true;
}

  goBackToTable() {
    this.router.navigate(['/contratacion/renting']);
  }

  openStateChangeModal(item: any): void {
    const dialogRef = this.dialog.open(ModalStatesComponent, {
      data: { state: item.value, function: () => this.stateActions[item.value]() },
    });
  
    dialogRef.afterClosed().subscribe((confirmed) => {
      this.selectedState = confirmed ? this.previousState : this.selectedState;
      if (confirmed) this.getStateSelectList();
    });
  }

  showEdit(item: any): void {
    item.editing = true;
  }

  onEditField(editedField: any, object: any) {
    if (editedField.key === 'firstPayment') return this.updateFirstPaymentDate(editedField.value);
  
    const section = this.getSection(editedField.sectionKey);
    if (section) {
      const targetField = section.find(({ key }: any) => key === editedField.key);
      targetField ? this.updateFieldValue(targetField, editedField.value) : console.warn('No se encontró el campo:', editedField.key);
      this.saveClientDetails(editedField, object);
      this.cdr.detectChanges();
    } else {
      console.warn('No se encontró la sección:', editedField.sectionKey);
    }
  }
  
  private getSection(sectionKey: string) {
    return {
      client: this.infoClient.data,
      address: this.infoAddress.data,
      shippingAddress: this.infoShippingAddress.data,
    }[sectionKey];
  }
  
  private updateFieldValue(targetField: any, value: any) {
    targetField.value = value;
  }
  
  hideEdit(item: any): void {
    item.editing = false;
  }

  isEditable(state: string): boolean {
    return (
      state === FINANCING_STATES.APPROVED ||
      state === FINANCING_STATES.PROCESSING
    );
  }

  saveClientDetails(editedField: any, object: any) {
    const updatedFields = this.buildUpdatedFields();
    const customerType = this.isCompany ? 'company' : 'autonomous';
  
    this.financingService.editCustomer(customerType, this.personalInfo.id, updatedFields).subscribe({
      next: () => {
        this.showAlertMessage('Datos del cliente actualizados con éxito.', 'ok');

        this.updateFieldInOperationInfo(editedField.key, editedField.value);
        if (editedField.key === 'contactName') {
          const [nameContact, ...surnameContactParts] = editedField.value.split(' ');
          const surnameContact = surnameContactParts.join(' ');
          
          this.operationInfo.customer.nameContact = nameContact || '-';
          this.operationInfo.customer.surnameContact = surnameContact || '-';
          object.nameContact = nameContact || '-';
          object.surnameContact = surnameContact || '-';
        } else {
          object[editedField.key] = editedField.value;
        }
        editedField.editing = false;
  
        this.refreshOperationInfo();
        this.cdr.detectChanges();
      },
      error: (error) => {
        console.error('Error al actualizar los datos del cliente:', error);
        this.showAlertMessage('Error al actualizar los datos del cliente.', 'ko');
      },
    });
  }
  
  private updateFieldInOperationInfo(key: string, value: any) {
    const addressKeys = ['address', 'city', 'province', 'zip'];
    if (addressKeys.includes(key)) {
      this.infoAddress.data.find((field: any) => field.key === key).value = value;
    } else if (key in this.personalInfo) {
      this.personalInfo[key] = value;
    } else {
      console.warn(`Campo no reconocido para actualizar: ${key}`);
    }
  }
  
  private refreshOperationInfo() {
    this.operationInfo = { ...this.operationInfo };
  
    this.initializeComponent();
  }

  private buildUpdatedFields() {
    const commonFields = this.buildCommonFields();
    const address = this.buildAddress(this.infoAddress);
    const shippingAddress = this.buildAddress(this.infoShippingAddress);
  
    if (this.isCompany) {
      return {
        ...commonFields,
        surname: null, 
        address,
        shippingAddress,
        contact: this.buildContact(),
      };
    }
  
    return {
      ...commonFields,
      address,
      shippingAddress,
    };
  }
  private buildContact() {
    const fullName = this.getFieldValue(this.infoClient, 'contactName') || '';
    const [nameContact, ...surnameContactParts] = fullName.split(' ');
    const surnameContact = surnameContactParts.join(' ');
    return {
      email: this.getFieldValue(this.infoClient, 'email'),
      phone: this.getFieldValue(this.infoClient, 'phone'),
      name: nameContact || '-',
      surname: surnameContact || '-',
    };
  }
  private buildCommonFields() {
    return {
      name: this.getFieldValue(this.infoClient, 'name'),
      surname: this.getFieldValue(this.infoClient, 'surname'),
      identifier: this.getFieldValue(this.infoClient, 'identifier'),
      iban: this.getFieldValue(this.infoClient, 'iban'),
      phone: this.getFieldValue(this.infoClient, 'phone'),
      email: this.getFieldValue(this.infoClient, 'email'),
    };
  }
  private buildAddress(section: any) {
    return {
      street: this.getFieldValue(section, 'address'),
      city: this.getFieldValue(section, 'city'),
      province: this.getFieldValue(section, 'province'),
      zip: this.getFieldValue(section, 'zip'),
    };
  }
  private getFieldValue(section: any, key: string): string {
    return section.data.find((field: any) => field.key === key)?.value || '';
  }

updateFirstPaymentDate(newDate: string): void {
  if (!newDate) return this.showAlertMessage('La fecha no puede estar vacía.', 'ko');

  this.financingService.updateInstallmentDate(this.operationInfo.id, newDate).subscribe({
    next: () => this.onFirstPaymentUpdateSuccess(newDate),
    error: (err) => this.onFirstPaymentUpdateError(err),
  });
}

private onFirstPaymentUpdateSuccess(newDate: string) {
  this.showAlertMessage('Fecha de primer pago actualizada con éxito.', 'ok');
  const firstPaymentField = this.conditions.data.find(({ key }: any) => key === 'firstPayment');
  if (firstPaymentField) firstPaymentField.value = this.datePipe.transform(newDate, 'dd/MM/yy');
}

private onFirstPaymentUpdateError(err: any) {
  console.error('Error al actualizar la fecha de primer pago:', err);
  this.showAlertMessage('Error al actualizar la fecha de primer pago.', 'ko');
}

}
