import { Router } from '@angular/router';
import { Injectable, OnDestroy } from "@angular/core";
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, interval, Observable, Subscription, tap } from "rxjs";
import { LoginResponse } from "../models/login";
import { LoginService } from './login/login.service';
import { LoadingService } from './loading/loading.service';

@Injectable()
export class AuthService implements OnDestroy {
  token$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private logout: Subscription = new Subscription;

  private readonly requiredRoles = ['admin-role', 'ROLE_ADMIN', 'ADMIN'];

  constructor(public jwtHelper: JwtHelperService, private loginService: LoginService, private router: Router, private loadingService: LoadingService) {}


  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token!);
  }

  public hasRequiredRoles(): boolean {
    const token = localStorage.getItem('token');
    if (!token) return false;

    try {
      const decodedToken = this.jwtHelper.decodeToken(token);
      const roles: string[] = decodedToken?.realm_access?.roles || [];
      return roles.some(role => this.requiredRoles.includes(role));
    } catch (error) {
      console.error('Error al decodificar el token:', error);
      return false;
    }
  }

  public signIn(username: string, password: string, otp?: string): Observable<LoginResponse> {
    const req = {
      username: username,
      password: password,
      /* otp: otp */
    };
    /* this.loadingService.showLoading(); */
    return this.loginService.login(req)
    .pipe(
      tap(res => {
        localStorage.setItem('token', res.access_token!);
        this.setTokenSessionStorage();
        /* this.loadingService.hideLoading(); */

      }),

    )
  }
  getTokenSessionStorage(): Observable<boolean> {
    return this.token$.asObservable();
  }

  setTokenSessionStorage() {
    this.token$.next(this.checkToken());
  }
  checkToken() {
    if(localStorage.getItem('token')) {
      return true
    } else {
      return false
      
    }
  }

  public signOut() {
    localStorage.removeItem('token');
    this.setTokenSessionStorage();
    this.router.navigate(['/login']);
  }

  public token() {
    return localStorage.getItem('token')
  }

  ngOnDestroy() {
    this.logout.unsubscribe();
  }
}