import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FinancingService } from 'src/app/core/services/financing/financing.service';

@Component({
  selector: 'app-send-document-modal',
  templateUrl: './send-document-modal.component.html',
  styleUrls: ['./send-document-modal.component.scss']
})
export class SendDocumentModalComponent {
  signers: any[] = [];
  isLoading: boolean = false;
  documentName: string = ''; 

  constructor(
    public dialogRef: MatDialogRef<SendDocumentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private financingService: FinancingService
  ) {
    this.signers = data.signers.map((signer: any) => ({
      ...signer,
      selected: false
    }));
    this.documentName = data.documentName; 
  }

  get isSendDisabled(): boolean {
    return !this.signers.some(signer => signer.selected);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  sendDocument(): void {
    const selectedSigners = this.signers
      .filter(signer => signer.selected)
      .map(s => s.id);
  
    this.isLoading = true;
  
    this.financingService
      .sendSignersToSign(this.data.financingId, this.data.documentId, selectedSigners)
      .subscribe({
        next: () => {
          this.isLoading = false; 
          this.dialogRef.close(selectedSigners);
        },
        error: () => {
          this.isLoading = false; 
          this.dialogRef.close({ error: 'Error al enviar los firmantes' });
        }
      });
  }
}
