import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CardConfiguration } from 'src/app/core/models/cards/card';
import { Stepper } from 'src/app/core/models/step/step';
import { AddressProfile } from 'src/app/core/models/user/userData';
import { CardService } from 'src/app/core/services/cards/cards.service';

import { BusinessService } from 'src/app/core/services/user/business.service';
import { UserService } from 'src/app/core/services/user/user.service';
@Component({
  selector: 'app-cards-create',
  templateUrl: './cards-create.component.html',
  styleUrls: ['./cards-create.component.scss'],
})
export class CardsCreateComponent {
  public steps: any[] = [];
  public role!: string;
  public idClient!: string;
  public companyProfile: any;
  public individualProfile: any;
  public showConfig!: boolean;
  public accounts: any;

  Stepper = Stepper

  public currentStep: Stepper = Stepper.CARD_CONFIG;
  public cardConfigData!: CardConfiguration;
  public cardShippingData!: AddressProfile;

  public showAlert!: boolean;
  public alertStatus!: string;
  public alertMessage!: string;

  constructor(
    private route: ActivatedRoute,
    private companyData: BusinessService,
    private individualData: UserService,
    private accountsData: UserService,
    private router: Router,
    private createCardService: CardService
  ) {}

  ngOnInit() {

    this.route.paramMap.subscribe((params) => {
      this.idClient = params.get('id')!;
    });


    this.steps = [
      { title: 'Paso 1', subtitle: 'Configuración tarjeta' },
      { title: 'Paso 2', subtitle: 'Dirección de envío' },
      { title: 'Paso 3', subtitle: 'Resumen' },
    ];


    this.role = localStorage.getItem('type')!

    if (this.role == 'Empresa') {
      this.getBusinessProfile();
      this.getAccounts();
    } else if (this.role == 'Individual') {
      this.getIndividualProfile();
      this.getAccounts();
    }
  }

  getBusinessProfile() {
    this.companyData.getCompany(this.idClient).subscribe((res) => {
      if (res) {
        this.companyProfile = res;
        this.getAccounts()
      }
    });
  }

  getIndividualProfile() {
    this.individualData.getUser(this.idClient).subscribe((res) => {
      if (res) {
        this.individualProfile = res;
        this.getAccounts()
      }
    });
  }

  getAccounts() {
    this.accountsData.getAllAccounts(this.idClient)?.subscribe((res) => {
      if (res) {
        this.accounts = res.filter((elem) => elem.state === 'ACTIVATED');
        this.showConfig = true;
      }

    });
  }

  prevStep() {
    if (this.currentStep > Stepper.CARD_CONFIG) {
      this.currentStep--;
    }
  }

  nextStep() {
    if (this.currentStep < this.steps.length - 1) {
      this.currentStep++;
    }
  }

  setStep(step: Stepper) {
    this.currentStep = step;
  }

  receiveProductsList(event: CardConfiguration) {
    this.cardConfigData = event;
  }

  receiveCardShippingData(event: AddressProfile) {
    this.cardShippingData = event;
  }

  handleConfirm(dataCard: any) {
    const accountId = dataCard.associatedAccountId;
    const holderDataCard = {
      holderId: dataCard.holderId,
      alias: dataCard.alias
    };
  

    this.createCardService.createCard(this.idClient, accountId, holderDataCard).subscribe(
      (response) => {
        this.showAlert = true;
        this.alertStatus = 'ok';
        this.alertMessage = 'Tarjeta creada exitosamente.' 
        setTimeout(() => {
          if(this.role == 'Empresa'){
            this.router.navigate(['/empresas']);
          }else if (this.role == 'Individual'){
            this.router.navigate(['/particulares']);
          }
        }, 5000 )
      },
      (error) => {
        console.error('Error al crear la tarjeta:', error);
  
        let message = 'Error al procesar la solicitud. Inténtelo nuevamente.';
        if (error.status === 400) {
          this.showAlert = true;
          console.error('Datos inválidos:', error.error.errors);
          this.alertStatus = 'ko';
          this.alertMessage = 'Error: Los datos proporcionados no son válidos.';

          setTimeout(() => {
            if(this.role == 'Empresa'){
              this.router.navigate(['/empresas']);
            }else if (this.role == 'Individual'){
              this.router.navigate(['/particulares']);
            }
          }, 5000 )
        }
      }
    );
  }

}
