import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FinancingService } from 'src/app/core/services/financing/financing.service';

@Component({
  selector: 'app-upload-document-modal',
  templateUrl: './upload-document-modal.component.html',
  styleUrls: ['./upload-document-modal.component.scss'],
})
export class UploadDocumentModalComponent implements OnInit {
  uploadForm!: FormGroup;
  selectedFile?: File;
  preview!: string;
  hasImage: boolean = false;
  previewIsImage: boolean = false;
  nameFile?: string;
  upload: boolean = false;
  uploading: boolean = false;
  public isLoading: boolean = false;
  errorMessage: string = '';

  constructor(
    private dialogRef: MatDialogRef<UploadDocumentModalComponent>,
    private formBuilder: FormBuilder,
    private financingService: FinancingService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: string;
      idFinancing: string;
      existingDocuments: any[];
    }
  ) {
  }
  

  ngOnInit(): void {
    this.uploadForm = this.formBuilder.group({
      comment: [''],
      document: [''],
    });
    this.dialogRef.disableClose = true;
  }

  onCancel(): void {
    this.resetFileSelection();
    this.dialogRef.close();
  }

  truncateFileName(
    fileName: string | undefined,
    maxLength: number = 30
  ): string {
    if (!fileName) {
      return '';
    }
    return fileName.length > maxLength
      ? fileName.substring(0, maxLength) + ' [...]'
      : fileName;
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  
    if (!this.selectedFile) {
      this.errorMessage = 'No se seleccionó ningún archivo.';
      return;
    }
  
    const allowedTypes = ['application/pdf'];
  
    if (Array.isArray(this.data.existingDocuments)) {
      console.log(this.data.existingDocuments)
      const isDuplicate = this.data.existingDocuments.some(
        (doc) => doc.name === this.selectedFile!.name
      );
  
      if (isDuplicate) {
        this.errorMessage = `El documento "${this.selectedFile.name}" ya existe en la lista.`;
        this.resetFileSelection();
        return;
      }
    } else {
      console.warn('existingDocuments no es un array válido.');
    }
  
    if (!allowedTypes.includes(this.selectedFile!.type)) {
      this.errorMessage = 'Formato no permitido. Solo se aceptan PDF.';
      this.resetFileSelection();
      return;
    }
  
    this.nameFile = this.selectedFile.name;
    this.errorMessage = '';
    this.convertFileBase64(this.selectedFile!).then((img: any) => {
      this.preview = img.base;
      this.previewIsImage = this.preview.includes('image');
    });
    this.upload = true;
    this.hasImage = true;
  }
  

  resetFileSelection() {
    this.selectedFile = undefined;
    this.nameFile = undefined;
    this.upload = false;
  }

  async convertFileBase64(file: File) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve({ base: reader.result });
    });
  }

  onSubmit() {
    if (this.selectedFile) {
      this.isLoading = true;

      const description = this.uploadForm.get('comment')?.value;
      this.uploading = true;

      const uploadObservable =
        this.data.type === 'signers'
          ? this.financingService.uploadSigningDocuments(
              this.data.idFinancing,
              this.selectedFile,
              description
            )
          : this.financingService.uploadDocument(
              this.data.idFinancing,
              this.selectedFile,
              description
            );

      uploadObservable.subscribe({
        next: (response) => {
          this.uploading = false;

          const newDocument = {
            file: this.selectedFile,
            codeOperation: this.nameFile,
            state: 'Pendiente',
            date: new Date(),
            comment: description,
            previewUrl: URL.createObjectURL(this.selectedFile!),
          };

          this.dialogRef.close(newDocument);
        },
        error: (err) => {
          alert('Error al subir el documento: ' + err.message);
        },
      });
    }
  }
}
 