<div class="company-data-content">
    <form [formGroup]="companyData" class="company-data-form">
      <div class="first">
        <mat-form-field appearance="outline" >
          <mat-label >Nombre fiscal</mat-label>
          <input matInput formControlName="name" />
          <mat-error
            *ngIf="!companyData.get('name')?.valid"
            >Introduce un nombre</mat-error
          >
        </mat-form-field>
        <mat-form-field appearance="outline" class="long_input">
          <mat-label >Nombre comercial</mat-label>
          <input matInput formControlName="brand" />
          <mat-error
            *ngIf="!companyData.get('brand')?.valid"
            >Introduce un nombre</mat-error
          >
        </mat-form-field>
        <mat-form-field appearance="outline" class="long_input">
          <mat-label>Prefijo</mat-label>
          <mat-select name="prefix" formControlName="prefix">
            <mat-option *ngFor="let item of prefix" [value]="item.prefix">
              {{ item.prefix }} ({{ item.country }})
            </mat-option>
          </mat-select>
          <mat-error *ngIf="!companyData.get('prefix')?.valid"
            >Debe introducir un prefijo</mat-error
          >
        </mat-form-field>
        <mat-form-field appearance="outline" class="long_input">
          <mat-label>Teléfono</mat-label>
          <input matInput formControlName="phone" />
          <mat-error
            *ngIf="!companyData.get('phone')?.valid"
            >Debe introducir un teléfono correcto</mat-error
          >
        </mat-form-field>
        <mat-form-field appearance="outline" class="long_input">
          <mat-label>Email</mat-label>
          <input type="email" matInput formControlName="email" />
          <mat-error
            *ngIf="!companyData.get('email')?.valid"
            >Debe introducir un email correcto</mat-error
          >
        </mat-form-field>
        <mat-form-field appearance="outline" class="long_input">
          <mat-label >CIF</mat-label>
          <input matInput formControlName="vatNumber" />
          <mat-error
            *ngIf="!companyData.get('cif')?.valid"
            >Fomato de CIF no válido</mat-error>
        </mat-form-field>
        <mat-form-field class="long_input" appearance="outline">
          <mat-label>Fecha de constitución</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="incorporationDate"
            (dateChange)="formattedDate()"
            [max]="today"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" class="long_input">
          <mat-label>CNAE</mat-label>
          <input
            [formControl]="cnaeControl"
            #cnaeInput
            type="text"
            placeholder="Selecciona o escribe el CNAE"
            matInput
            [matAutocomplete]="auto"
            (input)="filterCnae()"
            (focus)="focusCnae()">
          <mat-icon matSuffix class="arrow-icon">arrow_drop_down</mat-icon>
          <mat-autocomplete
            #auto="matAutocomplete"
            [displayWith]="displayFn"
            (optionSelected)="setCnae($event.option.value)">
            <mat-option *ngFor="let option of filteredOptions" [value]="option">
              {{option.code}} - {{option.name}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="!cnaeControl.valid">Debe seleccionar un CNAE</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="long_input">
          <mat-label matTooltip="Nivel de recursos propios">Nivel de RRPP</mat-label>
          <mat-select matInput formControlName="levelOwnResources">
            <mat-option *ngFor="let item of rrppLevelList" [value]="item.value">
            {{item.viewValue}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="!companyData.get('levelOwnResources')?.valid"
            >Seleccione una opción</mat-error
          >
        </mat-form-field>
        <mat-form-field appearance="outline" class="long_input">
          <mat-label>Volumen de ventas</mat-label>
          <mat-select name="salesVolume" formControlName="salesVolume">
            <mat-option *ngFor="let item of volumeSelectBusiness" [value]="item.value">
               {{ item.viewValue }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="!companyData.get('salesVolume')?.valid"
            >Debe introducir una cantidad</mat-error
          >
        </mat-form-field>
        <mat-form-field appearance="outline" class="long_input">
          <mat-label>Nivel de ingresos</mat-label>
          <mat-select name="income" formControlName="income">
            <mat-option *ngFor="let item of incomeSelectBusiness" [value]="item.value">
               {{ item.viewValue }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="!companyData.get('income')?.valid"
            >Debe introducir una cantidad</mat-error
          >
        </mat-form-field>
        <mat-form-field appearance="outline" class="long_input">
          <mat-label>Nacionalidad</mat-label>
          <mat-select name="nationality" formControlName="country">
            <mat-option *ngFor="let item of country" [value]="item.value">
              {{ item.viewValue }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="!companyData.get('country')?.valid"
            >Debe introducir una nacionalidad</mat-error
          >
        </mat-form-field>
        <mat-form-field appearance="outline" class="long_input">
          <mat-label >País expedición</mat-label>
          <mat-select name="nationality" formControlName="expeditionCountry">
            <mat-option *ngFor="let item of country" [value]="item.value">
              {{ item.viewValue }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="!companyData.get('expeditionCountry')?.valid"
            >Debe introducir una nacionalidad</mat-error
          >
        </mat-form-field>
        <mat-form-field appearance="outline" class="long_input">
          <mat-label>Crea un PIN</mat-label>
          <input
            id="dni"
            type="password"
            autocomplete="off"
            matInput
            formControlName="password"
            maxlength="6"
          />
          <mat-error
            *ngIf="!companyData.get('password')?.valid"
            >Debe contener 6 números</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="outline" class="long_input">
          <mat-label>Confirma el PIN</mat-label>
          <input
            type="password"
            matInput
            formControlName="rePassword"
            maxlength="6"
          />
          <mat-error
            *ngIf="companyData.get('rePassword')?.hasError('rePasswordError')"
            >El PIN debe coincidir
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>
