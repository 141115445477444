import {Component, Inject} from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

export interface DialogData {
  animal: 'panda' | 'unicorn' | 'lion';
}

/**
 * @title Injecting data when opening a dialog
 */

@Component({
  selector: 'dialog-data-stepper-dialog',
  templateUrl: './user-stepper.component.html',
  styleUrls: ['./user-stepper.component.scss'],
  standalone: true,
  imports: [MatDialogContent, CommonModule, MatIconModule, MatButtonModule],
})


export class UserStepper {


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<UserStepper>,) {}

  closeDialog(){
    this.dialog.closeAll()
  }
}
