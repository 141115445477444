import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { FilterParams } from 'src/app/core/models/filters/filters.model';
import { ActivityListMockService } from 'src/app/core/services/selects/activityLists';

@Component({
  selector: 'app-filters-business',
  /*   standalone: true,
  imports: [], */
  templateUrl: './filters-business.component.html',
  styleUrl: './filters-business.component.scss'
})
export class FiltersBusinessComponent implements OnInit, OnDestroy {
  public params = new HttpParams();
  public filterForm!: FormGroup;
  public cnaeList: any;
  public maxDate = new Date();
  @Output() filtersEvent = new EventEmitter<FilterParams>();
  public filteredOptions!: any[];
  public cnaeControl = new FormControl('', [Validators.required]);
  @ViewChild('cnaeInput') cnaeInput!: ElementRef<HTMLInputElement>;
  private destroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private cnae: ActivityListMockService
  ) {
    this.filterForm = this.formBuilder.group({
      createdFrom: [],
      createdTo: [],
      cnae: []
    });
  }

  ngOnInit(): void {
    this.cnae
      .cnaeSelectList()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => (this.cnaeList = res),
        error: (err) => console.error('Error loading CNAE list:', err),
      });
    this.filterForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.onSubmit());

    this.filterForm.get('createdFrom')?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.validateDates());

    this.filterForm.get('createdTo')?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.validateDates());
  }

  onSubmit(): void {
    const dataFromForm = {
      createdFrom: this.datePipe.transform(
        this.filterForm.get('createdFrom')?.value,
        'yyyy-MM-ddTHH:mm:ss'
      ),
      createdTo: this.datePipe.transform(
        this.filterForm.get('createdTo')?.value,
        'yyyy-MM-ddTHH:mm:ss'
      ),
      cnae: this.filterForm.get('cnae')?.value,
    };

    const filtersToSend: FilterParams = {}
     dataFromForm.createdFrom ? filtersToSend.createdFrom = dataFromForm.createdFrom : null;
     dataFromForm.createdTo ? filtersToSend.createdTo = dataFromForm.createdTo : null;
     dataFromForm.cnae ? filtersToSend.cnae = dataFromForm.cnae : null;
    this.filtersEvent.emit(filtersToSend)
  }

  resetAll() {
    this.filterForm.reset();
    this.cnaeControl.reset();
  }

  focusCnae() {
    if (this.cnaeInput.nativeElement.value) {
      this.filteredOptions = this.cnaeList;
    } else {
      this.filterCnae();
    }
  }

  filterCnae(): void {
    const filterValue = this.cnaeInput.nativeElement.value.toLowerCase();
    if (!filterValue) {
      this.filterForm.get('cnae')?.reset();
    }
    this.filteredOptions = this.cnaeList.filter((o: { code: string; name: string; }) =>
      o.code.toLowerCase().includes(filterValue) ||
      o.name.toLowerCase().includes(filterValue) ||
      `${o.code.toLowerCase()} - ${o.name.toLowerCase()}`.includes(filterValue)
    );
    if (this.filteredOptions.length === 0) {
      this.filterForm.get('cnae')?.reset();
    }
  }

  setCnae(event: any) {
    this.filterForm.get('cnae')?.setValue(event.code);
  }

  displayFn(cnae: any): string {
    return cnae && cnae.code ? `${cnae.code} - ${cnae.name}` : '';
  }

  validateDates(): void {
    const from = this.filterForm.get('createdFrom')?.value;
    const to = this.filterForm.get('createdTo')?.value;

    if (from && to && from > to) {
      this.filterForm.get('createdTo')?.setErrors({ invalidRange: true });
    } else {
      this.filterForm.get('createdTo')?.setErrors(null);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
