import { CurrencyPipe } from "@angular/common";

export function toPanSecretResume(text: string){
    const asterisk = '******';
  return text.replace(/^[A-Z]{2}[0-9]{2,18}/, asterisk); 
  } 

export function toHideAmount(number: number){
    const numToString = number.toString();
    const asterisk = '*';
    return numToString.replace(/[0-9.]/g, asterisk) + '€';
  } 

  export function setCurrencyFormat(initialNumber: string) {
    const formattedAmount = new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(Number(initialNumber));
    return formattedAmount;
  }

  export function containsNumbers(str: string) {
    return /[0-9]/.test(str);
  }
  export function preventLetters(event: KeyboardEvent) {
    const key = event.key;
    if ((key >= 'a' && key <= 'z') || key === '.') {
      event.preventDefault(); // Evitar la entrada de caracteres
    }
  }
  export function  getPreviusAndCurrentMonth() {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    
    // Primer día del mes anterior
    const firstDayOfLastMonth = new Date(currentYear, currentMonth - 1, 1);
    
    // Último día del mes anterior
    const lastDayOfLastMonth = new Date(currentYear, currentMonth, 0);
    
    // Primer día del mes en curso
    const firstDayOfCurrentMonth = new Date(currentYear, currentMonth, 1);
    
    // Día actual del mes en curso
    const currentDay = today;
    
    return {
      firstDayOfLastMonth,
      lastDayOfLastMonth,
      firstDayOfCurrentMonth,
      currentDay,
    };
  }
  
  export function formatAsPercentage(value: number): string {
    const percentage = value * 100;
    
    return Number.isInteger(percentage) 
      ? `${percentage}%` 
      : `${percentage.toFixed(2).replace('.', ',')}%`;  
  }

  export function formatDate(dateString: string): string {
    const date = new Date(dateString);
  
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
    const year = date.getFullYear();
  
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
  
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  export function formatDateOnlyDay(date: string | null | undefined): string {
    if (!date) {
      return '-';
    }
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  }
  