import { HttpParams } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { debounceTime, startWith } from 'rxjs';
import { UserData } from 'src/app/core/models/user/userData';
import { CardsMockService } from 'src/app/core/services/mocks/cards/cards.mock-service';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent {

  public cardList!: UserData[]
  public accumulatedData: UserData[] = [];
  public dataSource: any = [];
  public displayedColumns: string[] = ['id', 'account', 'iban','category', 'name', 'surname', 'issueData','active', 'links']
  public querySearch = new FormControl()
  public loader!: boolean;
  public offset: number = 10
  public currentPage: number = 1
  public cardResponse!: any

  public filters: HttpParams = new HttpParams()

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private userService: UserService, private router: Router, private cardService: CardsMockService) { }

  ngOnInit(): void {
    
    this.querySearch.valueChanges.pipe(debounceTime(300)).subscribe(res => {
      if(res){
      this.filters = this.filters.set("query", this.querySearch.value)
      }else{
        this.filters = this.filters.delete("query")
      }

    })
  }
  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }
  
  getUser(userId: string){
    localStorage.setItem('userId', userId)
    this.router.navigate(['/clientes', userId])
  }
  getCard(userId: string){
    localStorage.setItem('userId', userId)
    this.router.navigate(['/clientes', userId,'cuentas'])
  }
  public getDetail(userId: string, idCard:number){
    localStorage.setItem('userId', userId)
    this.router.navigate(['/clientes', userId, 'info-tarjeta', idCard]);
  }

  receiveFilters(filters: HttpParams) {
    this.filters = filters;
    if(this.querySearch.value){
    this.filters = this.filters.set("query", this.querySearch.value)
    }else{
      this.filters = this.filters.delete("query")
    }
/*     this.getCards() */
  }

  onPageChange(event: PageEvent){
    this.currentPage = event.pageIndex+1
    this.filters = this.filters.set("page", (event.pageIndex + 1).toString())
/*     this.getCards() */
  }

}
