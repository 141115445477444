import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { stateSigningDocuments } from 'src/app/core/extensions/stateFinancing.extension';

@Component({
  selector: 'app-document-detail-modal',
  templateUrl: './document-detail-modal.component.html',
  styleUrls: ['./document-detail-modal.component.scss']
})
export class DocumentDetailModalComponent {
  stateSigningDocuments = stateSigningDocuments
  constructor(
    public dialogRef: MatDialogRef<DocumentDetailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  closeModal(): void {
    this.dialogRef.close();
  }
}
